
@media (max-width:1600px){
	.bnr-side-img {
		position: relative;
		z-index: 1;
		cursor: pointer;
		width: 80%;
		margin: 0 auto;
	}
	.main-banner, .banner-img1, .banner-img2{
		height: 700px;
	}
	section.inner-banner.about-banner {
		height: 700px;
	}
	.inner-banner {
		height: 700px;
	}
	.indusry-main-banner {
		height: 700px;
	}

	.indusry-main-banner {
		height: 700px;
	}
	
	.industry-about-sec .hd-txt h2 {
		font-size: 50px;
	}
	
	.industry-about-sec .hd-txt h2 br {
		display: none;
	}
	
	.industry-about-sec .hd-txt p {
		font-size: 16px;
	}
	
	.empty-sec {
		height: 200px;
	}
	
	.inner-industry-txt-side.hd-txt h2 {
		font-size: 50px;
	}
	
	.inner-industry-txt-side.hd-txt p {
		font-size: 16px;
	}
	
	.inner-industry-txt-side-img img {
		width: 95%;
	}
	
	section.cta-sec-industry .hd-txt h2 {
		font-size: 45px;
	}
	
	.hd-txt.indus-mob-txt h2 {
		font-size: 38px;
	}
	
	.mob-indus-side-img img {
		width: 70%;
	}
	
	section.logo-design-industry-sec .hd-txt h2 {
		font-size: 45px;
	}
	
	section.logo-design-industry-sec .hd-txt p {
		font-size: 15px;
	}
	
	.logo-indus-side-img li img {
		width: 80%;
		margin: 0 auto;
		display: table;
	}

}
@media (max-width:1550px){
	.main-banner {
		height: 700px;
		padding: 140px 0 50px;
	}
	.cta-sec img.cta-side-icon-st-1 {
		position: absolute;
		right: -80px;
		bottom: 140px;
	}
	
	.cta-sec img.cta-side-icon-st-2 {
		position: absolute;
		right: -40px;
		top: auto;
		bottom: 60px;
		width: 100px;
	}
	
	.cta-sec img.cta-side-icon-st-3 {
		position: absolute;
		right: 0px;
		bottom: -80px;
		top: auto;
	}
	.about-mobile-img img {
		width: 74%;
		top: 10px;
	}
	
	span.circle-mobile-app {
		width: 450px;
		height: 450px;
		top: 270px;
	}
	.digital-serv-box p {
		min-height: 250px;
		
	}
	.why-choose-slider-inr-col .why-box-inr p {
		font-size: 14px;
		
	}
	
	.bnr-side-img img {
		width: 100%;
		margin: 0 auto;
		display: table;
	}
	.about-sec {
		padding-top: 140px;
	}
	
	.bnr-side-img {
		margin-top: -30px;
		top: -10px;
	}
	
	.bnr-txt h1 {
		font-size: 40px;
	}
	
	.bnr-txt p {
		font-size: 15px;
		padding-bottom: 10px;
	}
	
	.bnr-txt ul {
		margin-bottom: 25px;
	}
	
	.main-banner .banner-img1, .main-banner .banner-img2 {
		height: 700px;
	}
	
	.about-txt.hd-txt h2 {
		font-size: 32px;
		line-height: 40px;
	}
	
	.about-list ul .about-sol-bx {
		height: 100px;
	}
	
	.cta-txt.hd-txt h2 {
		font-size: 50px;
		width: 70%;
		line-height: 55px;
	}
	section.digtal-mrkt-service-sec.portfolio-main-pg-sec img.port-bf-img{
		display: none;
	}
	.cta-txt.hd-txt h2 br {
		display: none;
	}
	.cta-sec img {
		right: 0;
	}
	
	.cta-sec.cta-ecommerce-sec {
		
		height: 380px;
	}
	
	.hd-txt h2 {
		font-size: 35px;
	}
	
	.web-sol-rft-div h2 {
		font-size: 30px;
	}
	
	.indus-box h3 {
		font-size: 20px;
	}
	.main-web-solution-col{
		padding: 40px 20px;
	}
	.ftr-main-sec{
		padding: 20px 20px 70px;
	}
	.hd-txt.tech-web-txt h2 {
		font-size: 45px;
		line-height: 40px;
	}
	
	.hd-txt.tech-web-txt h2 span {
		font-size: 100px;
		line-height: 105px;
	}
	
	.technology-stack {
		padding: 0;
	}
	
	.testi-mobile {width: 28%;}
	
	.testi-box p {
		width: 70%;
	}
	
	.blitz-logo-ftr-st {
		width: 80%;
		margin: 0 auto;
	}
	.testi-sec .hd-txt {
		padding-bottom: 190px;
	}
	.testi-mobile {width: 30%;top: 210px;}
	.ftr-lft-div a p {
		width: 75%;
	}

	.logo-services-slider .slick-dots {
		bottom: -33px;
		z-index: 4;
	}

	.inner-banner {
		height: 700px;
	}
	
	.logo-services-slider .slick-dots {
		bottom: -33px;
		z-index: 4;
	}
	
	.cta-txt.logo-cta-txt.hd-txt h2 {
		width: 100%;
	}
	
	.cta-logo-sec .cta-side-img img {
		right: 0;
		width: 100%;
		top: 15px;
	}
	
	.amout-bx:before {
		width: 100%;
	}
	
	.pricing-btn a {
		font-size: 14px;
	}
	
	.why-box-inr h3 {
		font-size: 24px;
	}
	
	.process-inr-bg {left: -180px;}
	
	.process-inner-sec .hd-txt {
		position: relative;
		z-index: 2;
	}
	
	.process-inner-sec {
		overflow: hidden;
		padding-bottom: 120px;
		margin-bottom: -30px;
	}

	.navbar-nav li a{
		font-size: 14px;
	}
	.logo-serv-box h3 {
		font-size: 24px;
		padding: 20px 0;
	}
	
	.logo-serv-box p {
		font-size: 14px;
	}
	.pricing-main-col {
		padding: 0 20px !important;
	}
	.about-sec-webdesgin .hd-txt p {
		width: 100%;
		font-size: 16px;
	}
	
	.about-webdesgin-side-img .about-s-web-img-1 {
		width: 85%;
	}
	
	.about-sec-webdesgin {
		padding: 80px 0;
	}
	
	.web-serv-main-col {
		padding: 0 20px !important;
	}
	
	.web-serv-box-m h2 {
		font-size: 35px;
	}
	
	.cta-txt.web-cta-txt.hd-txt h2 {
		width: 100%;
		line-height: 70px;
	}
	
	.cta-side-img-web img {
		width: 67%;
	}
	
	.cta-sec.cta-web-sec {
		/* padding: 0; */
		height: auto;
	}
	.hd-txt.serv-portal-txt h2 {
		font-size: 35px;
	}
	
	.portal-serv-box h3 {
		font-size: 20px;
	}
	.digital-serv-box h3 {
		font-size: 20px;
	}
	
	.cta-sec.cta-digital-sec .cta-side-img img {
		/* width: 100%; */
		right: 0;
	}
	.serv-web-txt.hd-txt h2 {
		font-size: 35px;
	}
	
	.serv-mid-img-ecom img {
		width: 70%;
	}
	
	.serv-web-quality-txt img {
		width: auto;
	}
	
	.our-servcies-sec-web {
		padding: 50px 0;
	}
	.inr-bnr-txt h2 {
		font-size: 40px;
	}
	section.inner-banner.about-banner {
		height: 600px;
	}
	
	.inner-about-main-sec .hd-txt p {
		font-size: 16px;
	}
}



@media (max-width:1400px){
	.container{
		width: 1200px;
		max-width: 1200px;
	}
	.mobile-mock-up-serv {
		left: 0px;
	}
	.mobile-services .hd-txt.mob-serv-txt p {
		width: 75%;
	}
	.mobile-serv-slider-main-dv .slick-dots {
		left: -505px;
	}
	.slick-current .mobile-serv-inr-div {
		height: 250px;
	}
	
	
}
@media (max-width:1366px){
	.navbar-nav li a {
		font-size: 15px;
		padding: 0 20px !important;
	}
}

@media (max-width:991px){
	.container{
		width: 100%;
		max-width: 100%;
	}


.industry-banner-txt h2 {
    font-size: 30px;
}

.industry-banner-txt p {
    width: 100%;
}

.indusry-main-banner {
    height: 550px;
}

.industry-about-sec .hd-txt h2 {
    font-size: 30px;
}

.industry-about-sec {
    padding: 50px 0;
}

.industry-about-sec .hd-txt p {
    font-size: 14px;
    line-height: 23px;
}

.about-industry-img img {
    width: 92%;
    z-index: 1;
    position: relative;
}

.empty-sec {
    height: 80px;
    margin-top: -20px;
}

.about-industry-img {
    position: relative;
    z-index: 1;
}

.inner-industry-txt-side.hd-txt h2 {
    font-size: 30px;
}

.inner-industry-txt-side.hd-txt p {
    margin: 0;
    width: 100%;
    font-size: 14px;
    line-height: 22px;
}

.inner-industry-txt-side-img img {
    width: 50%;
    margin: 0 auto;
    display: table;
}

.inner-industry-txt-side-img {
    top: 0;
    margin-bottom: -50px;
}

section.cta-sec-industry .hd-txt h2 {
    font-size: 30px;
    font-weight: 700;
}

section.cta-sec-industry .bnr-btn {
    width: 100%;
}

.moible-app-sec-industry .col-sm-4:nth-child(1) {
    display: none;
}

.hd-txt.indus-mob-txt h2 {
    font-size: 25px;
}

.hd-txt.indus-mob-txt h2 br {
    display: none;
}

.logo-indus-side-img ul {
    gap: 30px 10px;
}

.logo-indus-side-img ul img {
    width: 60%;
}

.logo-indus-side-img ul li {
    width: 48%;
    margin: 0 auto;
}

.logo-indus-side-img ul li img {width: 100%;}

section.logo-design-industry-sec .hd-txt p {
    width: 100%;
    line-height: 27px;
    padding-bottom: 0;
}

section.logo-design-industry-sec .hd-txt h2 {
    font-size: 30px;
}

	h2.mobile-outline-txt-st {
		display: none;
	}
	section.digtal-mrkt-service-sec.portfolio-main-pg-sec {
		padding-top: 0;
	}
	.pricing-sec {
		padding-bottom: 0;
	}
	
	.pricing-box {
		margin: 0;
	}
	.main-banner {
		height: auto;
		overflow: hidden;
		padding-bottom: 0;
	}
	
	.main-banner .banner-img1, .main-banner .banner-img2 {
		height: 743px;
	}
	.about-sec {
		padding-top: 50px;
	}
	
	.bnr-side-img img {
		width: 80%;
	}
	
	.hd-txt h4 {
		text-align: center;
		font-size: 18px;
	}
	
	.container {}
	
	.bnr-txt h1 {
		font-size: 30px;
		text-align: center;
	}
	
	.bnr-txt h1 br {
		display: none;
	}
	
	.main-banner.active h1, .main-banner.active p {
		text-align: center;
	}
	
	.bnr-txt ul li {
		width: 100%;
		text-align: center;
	}
	
	.bnr-btn {
		justify-content: center;
		margin-bottom: 40px;
	}
	
	.about-txt.hd-txt h2 {
		text-align: center;
		font-size: 28px;
	}
	
	.hd-txt p {
		text-align: center;
	}
	
	.about-list {
		padding-right: 0;
	}
	
	.about-sol-bx h4 {
		font-size: 15px;
	}
	
	.about-list ul .about-sol-bx {
		height: 80px;
	}
	
	.cta-txt.hd-txt h2 {
		font-size: 30px;
		width: 100%;
		-webkit-text-stroke: 1px #000;
	}
	
	.cta-txt.hd-txt h2 br {
		display: none;
	}
	
	.cta-sec img {
		display: none;
	}
	
	.cta-sec .cta-tel {
		flex-direction: column;
		gap: 8px 0;
	}
	
	.main-port-div .port-bx img {
		margin: 0 auto;
		display: table;
	}
	
	.hd-txt h2 {
		font-size: 28px;
		text-align: center;
	}
	
	ul.how-list-st li {
		width: 100%;
		text-align: center;
	}
	
	ul.how-list-st li:nth-child(odd) .how-box {
		border: 0;
	}
	
	ul.how-list-st li p {
		width: 100%;
	}
	
	.how-box img {
		display: none;
	}
	
	.how-box h3 {
		font-size: 19px;
	}
	
	.web-solution-sec .hd-txt {
		width: 100%;
	}
	
	.main-web-solution-col {
		flex-direction: column;
		padding: 0 10px;
	}
	
	.web-sol-lft-col {
		padding-right: 0;
	}
	
	.web-solution-sec ul li .web-sol-bx {
		flex-direction: column;
		text-align: center;
		justify-content: center;
	}
	
	.web-solution-sec ul li .web-sol-bx img {
		margin: 0 auto;
	}
	
	.web-sol-lft-col p {
		text-align: center;
		font-size: 16px;
	}
	
	.web-sol-rft-div video {
		width: 100%;
		margin-top: 15px;
	}
	
	.web-sol-rft-div p {
		margin: 0 auto;
		text-align: center;
		font-size: 16px;
		width: 100%;
	}
	
	.web-sol-rft-div h2 {
		text-align: center;
		font-size: 28px;
	}
	
	.web-sol-rft-div .bnr-btn {
		margin: 0;
	}
	
	.main {
		transform: scale(0.7);
		margin: 0 auto;
		display: table;
		width: 400px;
		height: 400px;
		margin-left: -20px;
	}
	
	.icon-block {
		width: 80px;
		height: 80px;
	}
	
	.icon-block img {
		width: 50px;
	}
	
	.hd-txt.tech-web-txt h2 {
		text-align: center;
		font-size: 28px;
	}
	
	.hd-txt.tech-web-txt h2 span {
		font-size: 31px;
		line-height: 42px;
		margin: 0 8px;
	}
	
	.hd-txt.tech-web-txt p {
		width: 100%;
		text-align: center;
	}
	
	.hd-txt.tech-web-txt h2 {display: flex;flex-wrap: wrap;text-align: center;justify-content: center;}
	
	.testi-mobile {
		width: 100%;
	}
	
	.testi-main-col .slick-prev {
		left: 10px;
	}
	
	.testi-main-col .slick-next {
		right: 10px;
	}
	
	.testi-box p {
	}
	
	.blitz-logo-ftr-st {
		width: 100%;
		top: -22px;
	}
	
	.testi-sec {
		padding-bottom: 60px;
		overflow: hidden;
	}
	
	.cta-txt.hd-txt h2 {
		padding-top: 0;
	}
	
	section.cta-sec.ftr-cta-sec {
		padding-top: 30px;
		padding-bottom: 10px;
	}
	
	.services-list ul {text-align: center;}
	
	.services-list ul li {
		width: 100%;
	}
	
	.ftr-main-col {
		flex-direction: column;
	}
	
	.services-list ul li a {
		font-size: 15px;
		line-height: 28px;
	}
	
	.services-list h4 {
		text-align: center;
		font-size: 28px;
		padding-top: 10px;
	}
	
	.ftr-main-col .col-sm-7 {
    	flex-direction: column;
	}
	.ftr-lft-div h4 {
		font-size: 28px;
		text-align: center;
		margin-top: 15px;
	}
	
	.ftr-lft-div a {
		justify-content: center;
	}
	
	.social-icon {
		justify-content: center;
	}
	
	.ftr-copy-main-col {
		flex-direction: column;
		justify-content: center;
		text-align: center;
	}
	
	.ftr-copy-main-col img {
		width: 90%;
		margin-top: 15px;
	}
	
	.ftr-main-sec:before {
		display: none;
	}
	
	.ftr-copy-main-col p {
		font-size: 15px;
	}
	
	.ftr-main-sec {
		padding-bottom: 30px;
	}
	
	.portfolio-sec {
		overflow: hidden;
		padding-bottom: 0px;
	}
	.slick-slide .port-bx {
		margin-right: 0;
	}
	section {
		overflow: hidden;
	}
	.mobile-nav {
		display: block;
		overflow-x: hidden;
	}
	
	button.navbar-toggler {
		display: none;
	}
	
	.cta-btn.header-btn {
		display: none;
	}
	.center-logo img {
		width: 80%;
		max-width: 80%;
		margin: 0 auto;
		display: table;
	}
	.center-logo img {
		width: 80%;
		max-width: 80%;
		margin: 0 auto;
		display: table;
	}
	
	.inr-bnr-txt h2 {
		font-size: 30px;
	}
	
	section.inner-banner.about-banner .inr-bnr-txt p {
		width: 100%;
	}
	
	.inner-about-main-sec .hd-txt p {
		width: 100%;
		padding-bottom: 0;
	}
	
	.main-about-side-img img {
		margin-bottom: 30px;
	}
	
	.rating-main-col .hd-txt h2 {
		font-size: 24px;
	}
	
	.rating-main-col .hd-txt h2:after {
		display: none;
	}
	
	.rating-main-col .hd-txt h2:before {
		display: none;
	}
	
	.rating-list ul li {
		width: 50%;
	}
	
	.rating-list ul {
		flex-wrap: wrap;
		margin: 0;
		gap: 20px 0;
	}
	
	.rating-main-col {
		height: 100%;
	}
	
	.why-choose-sec-inner .hd-txt p {
		width: 100%;
	}
	
	.rating-sec {
		padding-bottom: 0;
	}
	
	.why-box-inr {
		text-align: center;
	}
	
	.why-box-inr img {
		margin: 0 auto;
	}
	
	.why-box-inr h3:before {
		display: none;
	}
	
	.what-sec-inner ul li {
		width: 30%;
	}
	
	.what-bx-inr h4 {
		font-size: 13px;
	}
	
	.process-inner-main-col ul li {
		width: 100%;
	}
	
	.process-inner-sec .hd-txt {
		padding: 0;
	}
	
	.process-inner-sec {
		padding-bottom: 0;
	}
	
	.navbar .container-fluid {
		padding: 0;
	}
	.center-logo img {
		width: 80%;
		max-width: 80%;
		margin: 0 auto;
		display: table;
	}
	
	.inr-bnr-txt h2 {
		font-size: 30px;
	}
	
	section.inner-banner.about-banner .inr-bnr-txt p {
		width: 100%;
	}
	
	.inner-about-main-sec .hd-txt p {
		width: 100%;
		padding-bottom: 0;
	}
	
	.main-about-side-img img {
		margin-bottom: 30px;
	}
	
	.rating-main-col .hd-txt h2 {
		font-size: 24px;
	}
	
	.rating-main-col .hd-txt h2:after {
		display: none;
	}
	
	.rating-main-col .hd-txt h2:before {
		display: none;
	}
	
	.rating-list ul li {
		width: 50%;
	}
	
	.rating-list ul {
		flex-wrap: wrap;
		margin: 0;
		gap: 20px 0;
	}
	
	.rating-main-col {
		height: 100%;
	}
	
	.why-choose-sec-inner .hd-txt p {
		width: 100%;
	}
	
	.rating-sec {
		padding-bottom: 0;
	}
	
	.why-box-inr {
		text-align: center;
	}
	
	.why-box-inr img {
		margin: 0 auto;
	}
	
	.why-box-inr h3:before {
		display: none;
	}
	
	.what-sec-inner ul li {
		width: 30%;
	}
	
	.what-bx-inr h4 {
		font-size: 13px;
	}
	
	.process-inner-main-col ul li {
		width: 100%;
	}
	
	.process-inner-sec .hd-txt {
		padding: 0;
	}
	
	.process-inner-sec {
		padding-bottom: 0;
	}
	
	.navbar .container-fluid {
		padding: 0;
	}
	
	nav.navbar.navbar-expand-lg {}
	
	section.inner-banner.logo-banner video {
		display: none;
	}
	
	.inner-banner {
		height: 550px;
	}
	
	.inr-bnr-txt h2 {
		text-align: center;
	}
	
	.inr-bnr-txt p {
		text-align: center;
		width: 100%;
	}
	
	.pricing-sec .hd-txt p {
		width: 100%;
	}
	
	.pricing-main-col {flex-direction: column;}
	
	.pricing-main-col .col-sm-3 {
		width: 100%;
	}
	
	.pricing-main-col {
		gap: 20px 0;
	}
	section.inner-banner.website-banner {
		background: linear-gradient(38deg, #35161b 25%, #040002 92%);
	}
	
	section.inner-banner.website-banner video {display: none;}
	
	.about-webdesgin-side-img .about-s-web-img-1 {
		top: 40px;
	}
	
	.about-s-web-img-2 {
		top: 0;
		display: none;
	}
	
	section.web-services-sec {
		background-position: revert;
	}
	
	.web-serv-main-col {
		flex-direction: column;
	}
	
	.web-serv-main-col .hd-txt {
		text-align: center;
		padding: 0;
	}
	
	.web-serv-box-m {
		text-align: center;
	}
	
	section.cta-sec.cta-web-sec {
		padding: 25px 0;
	}
	
	.cta-txt.web-cta-txt.hd-txt h2 {
		-webkit-text-stroke: 1px #fff;
	}
	section.inner-banner.website-banner {
		background: linear-gradient(38deg, #35161b 25%, #040002 92%);
	}
	
	section.inner-banner.website-banner video {display: none;}
	
	.about-webdesgin-side-img .about-s-web-img-1 {
		top: 40px;
	}
	
	.about-s-web-img-2 {
		top: 0;
		display: none;
	}
	
	section.web-services-sec {
		background-position: revert;
	}
	
	.web-serv-main-col {
		flex-direction: column;
	}
	
	.web-serv-main-col .hd-txt {
		text-align: center;
		padding: 0;
	}
	
	.web-serv-box-m {
		text-align: center;
	}
	
	section.cta-sec.cta-web-sec {
		padding: 25px 0;
	}
	
	.cta-txt.web-cta-txt.hd-txt h2 {
		-webkit-text-stroke: 1px #fff;
	}
	
	section.inner-banner.digital-mrkt-banner {
		background: linear-gradient(112deg, #deedf8 25%, #bab3ff 92%);
	}
	
	section.inner-banner.digital-mrkt-banner video {
		display: none;
	}
	
	.about-mid-digital {
		width: 85%;
	}
	
	.digital-serv-box {
		text-align: center;
	}
	
	.digital-serv-box img {
		margin: 0 auto;
	}
	
	.digital-serv-box h3 {
		text-align: center;
		justify-content: center;
	}
	
	.digital-serv-box p {
		text-align: center;
		margin: 0 auto;
	}
	
	.cta-sec.cta-digital-sec .row {
		margin-top: 0;
	}
	
	.cta-sec.cta-digital-sec {
		height: auto;
		padding: 30px 0;
	}
	
	.cta-sec.cta-digital-sec .cta-txt.web-cta-txt.hd-txt, .cta-sec.cta-digital-sec .cta-txt.web-cta-txt.hd-txt h2 {
		text-align: center;
	}
	.cta-sec.cta-ecommerce-sec .bnr-btn.about-btn {justify-content: center;}
	section.inner-banner.ecommerce-banner.web-portal-bnr {
		background: linear-gradient(38deg, #ccd2f1 25%, #a7dce6 92%);
	}
	
	section.inner-banner.ecommerce-banner.web-portal-bnr video {
		display: none;
	}
	
	.about-sec-web-portal .hd-txt p {
		width: 100%;
		font-size: 16px;
	}
	
	.about-portal-img img {
		margin-bottom: 15px;
	}
	
	.hd-txt.serv-portal-txt h2 {
		font-size: 28px;
	}
	
	.serv-portal-slide-main-col .slick-list {
		width: 100%;
	}
	
	.portal-serv-box h3 {
		text-align: center;
		justify-content: center;
	}
	
	.portal-serv-box p {
		text-align: center;
		width: 100%;
	}
	
	.portal-serv-box img {
		margin: 0 auto;
	}
	
	.cta-portal-sec {
		padding: 30px 0;
	}
	section.inner-banner.ecommerce-banner.web-portal-bnr {
		background: linear-gradient(38deg, #ccd2f1 25%, #a7dce6 92%);
	}
	
	section.inner-banner.ecommerce-banner.web-portal-bnr video {
		display: none;
	}
	
	.about-sec-web-portal .hd-txt p {
		width: 100%;
		font-size: 16px;
	}
	
	.about-portal-img img {
		margin-bottom: 15px;
	}
	
	.hd-txt.serv-portal-txt h2 {
		font-size: 28px;
	}
	
	.serv-portal-slide-main-col .slick-list {
		width: 100%;
	}
	
	.portal-serv-box h3 {
		text-align: center;
		justify-content: center;
	}
	
	.portal-serv-box p {
		text-align: center;
		width: 100%;
	}
	
	.portal-serv-box img {
		margin: 0 auto;
	}
	
	.cta-portal-sec {
		padding: 30px 0;
	}
	
	section.inner-banner.ecommerce-banner {
		background: linear-gradient(0deg, #f5fbff 25%, #c5f0ff 92%);
	}
	
	section.inner-banner.ecommerce-banner video {
		display: none;
	}
	
	.about-ecom-side-img img {
		width: 100%;
	}
	
	.serv-web-txt.hd-txt ul {
		justify-content: center;
	}
	
	.our-servcies-sec-web .row {
		justify-content: center;
	}
	
	.web-serv-box {
		text-align: center;
	}
	
	.web-serv-box img {
		margin: 0 auto;
	}
	
	.cta-txt.web-cta-txt.hd-txt h2 {
		text-align: center;
	}
	section.inner-banner.ecommerce-banner.web-portal-bnr {
		background: linear-gradient(38deg, #ccd2f1 25%, #a7dce6 92%);
	}
	
	section.inner-banner.ecommerce-banner.web-portal-bnr video {
		display: none;
	}
	
	.about-sec-web-portal .hd-txt p {
		width: 100%;
		font-size: 16px;
	}
	
	.about-portal-img img {
		margin-bottom: 15px;
	}
	
	.hd-txt.serv-portal-txt h2 {
		font-size: 28px;
	}
	
	.serv-portal-slide-main-col .slick-list {
		width: 100%;
	}
	
	.portal-serv-box h3 {
		text-align: center;
		justify-content: center;
	}
	
	.portal-serv-box p {
		text-align: center;
		width: 100%;
	}
	
	.portal-serv-box img {
		margin: 0 auto;
	}
	
	.cta-portal-sec {
		padding: 30px 0;
	}
	
	section.inner-banner.ecommerce-banner {
		background: linear-gradient(0deg, #f5fbff 25%, #c5f0ff 92%);
	}
	
	section.inner-banner.ecommerce-banner video {
		display: none;
	}
	
	.about-ecom-side-img img {
		width: 100%;
	}
	
	.serv-web-txt.hd-txt ul {
		justify-content: center;
	}
	
	.our-servcies-sec-web .row {
		justify-content: center;
	}
	
	.web-serv-box {
		text-align: center;
	}
	
	.web-serv-box img {
		margin: 0 auto;
	}
	
	.cta-txt.web-cta-txt.hd-txt h2 {
		text-align: center;
	}
	
	.cntct-form-r-side {
		width: 100%;
	}
	
	.conact-main-div {
		flex-direction: column;
	}
	
	.conact-main-div .col-sm-6 {
		width: 100%;
	}
	
	.cntct-form-r-side ul li a {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
	}
	
	.cntct-form-r-side ul {
		padding: 0 20px;
	}
	.contact-button-div {
		text-align: center;
		margin: 0 auto;
		margin-bottom: 20px;
	}
	.web-serv-main-slider .web-serv-box {
		padding: 20px 10px;
	}
	.port-af-img {
		display: none;
	}
	
	img.port-bf-img {
		display: none;
	}
	
	.port-bx img {
		width: 100% !important;
		height: 360px !important;
	}    
	section.inner-banner.ecommerce-banner.mobile-app-bnr {
		background: linear-gradient(0deg, #513031 25%, #12171e 92%);
	}
	
	.about-mob-txt p {
		width: 100%;
	}
	
	.about-mobile-img img {
		top: -20px;
	}
	
	span.circle-mobile-app {
		display: none;
	}
	
	.mobile-services .hd-txt.mob-serv-txt p {
		width: 100%;
	}
	
	.mobile-serv-slider-main-dv .slick-list {
		width: 100%;
	}
	
	.mobile-mock-up-serv {
		left: 0;
		right: 0;
		margin: auto;
	}
	.slick-current .mobile-serv-inr-div {
		
		padding: 0 10px;
	}
	.mobile-services .hd-txt {
		padding: 0 0 40px;
	}
	
	.mobile-serv-slider-main-dv .slick-dots {
		left: 45px;
		top: 623px;
		right: 0;
		margin: auto;
	}
	
	.mobile-app-devlopment-sec .mobile-app-dev-vid {
		display: none;
	}
	
	section.mobile-app-devlopment-sec .hd-txt {
		padding: 0;
	}
	
	section.mobile-app-devlopment-sec .hd-txt p {
		width: 100%;
	}
	
	.mob-app-dev-li-bx {
		flex-direction: column;
		justify-content: center;
		text-align: center;
	}
	
	.mob-app-dev-li-bx img {
		margin: 0 auto;
		margin-bottom: 15px;
	}
	.portfolio-sec .col-sm-4 {
		width: 100%;
	  }
	  .bnr-side-img{
		display: none;
	  }	
	  .desk_btn{
		display: none;
	}
	  .mobil_btn{   
		 display: flex;
		margin: 38px 0 0px 0;
	}
	.main.main-tech-div {
		display: none;
	}
	section.cta-sec.ftr-cta-sec{
		display: none;
	}
	.footer-bg-st{
		padding-top: 40px;
	}
	section.web-solution-sec .col-sm-12.main-web-solution-col > .col-sm-6 {
		width: 100%;
	}
	.web-solution-sec ul li .web-sol-bx{
		display: block;
	}
	section.main-banner.main-bnr-default .bnr-txt ul li {
		text-align: start;
	}
	.services-list h4 , .services-list ul li{
		text-align: start;
	}
	.social-icon , .ftr-lft-div a{
		justify-content: start;
	}
	.ftr-lft-div h4 {
		text-align: start;
	}
}